<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="flex justify-between flex-wrap">
            <v-btn
              v-if="addSettings"
              class="mb-2 mt-10 button"
              dark
              color="info"
              @click="$router.push('/app/settings/addSettings')"
            >
              <v-icon>mdi-plus</v-icon>
              Advance Settings
            </v-btn>
            <div></div>
          </div>
        </v-card-title>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit {{ settingsName }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-card outlined>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1 mb-4">
                            <h6 style="color: #424242">Settings Name</h6>
                            <v-card width="250" outlined class="pa-1">
                              {{ settingsCode }}
                            </v-card>
                          </v-list-item-title>
                          <v-list-item-title>
                            <div v-if="settingsType == 'bool'">
                              <h6 style="color: #424242">Settings Value</h6>
                              <v-switch
                                class="ml-4"
                                v-model="settingsValue"
                                :input-value="`${settingsValue}`"
                                @change="updateType(settingsValue, $event)"
                                inset
                              ></v-switch>
                            </div>
                            <div v-if="settingsType == 'integer'">
                              <v-col cols="12" md="12">
                                <v-form
                                  ref="form"
                                  v-model="valid"
                                  lazy-validation
                                >
                                  <v-text-field
                                    label="Value"
                                    :value="`${settingsValue}`"
                                    :rules="emptyRules"
                                    type="number"
                                    v-model="settingsValue"
                                    hide-details="auto"
                                    required
                                  >
                                    <v-btn
                                      slot="append"
                                      color="primary"
                                      @click="updateAlertLimit"
                                    >
                                      Update
                                    </v-btn>
                                  </v-text-field>
                                </v-form>
                              </v-col>
                            </div>
                            <div v-if="settingsType == 'text'">
                              <v-col cols="12" md="12">
                                <v-form
                                  ref="form"
                                  v-model="valid"
                                  lazy-validation
                                >
                                  <v-text-field
                                    label="Value"
                                    :value="`${settingsValue}`"
                                    :rules="emptyRules"
                                    type="text"
                                    v-model="settingsValue"
                                    hide-details="auto"
                                    required
                                  >
                                    <v-btn
                                      slot="append"
                                      color="primary"
                                      @click="
                                        updateText(settingsId, settingsCode)
                                      "
                                    >
                                      Update
                                    </v-btn>
                                  </v-text-field>
                                </v-form>
                              </v-col>
                            </div>
                          </v-list-item-title>
                          <v-list-item-title>
                            <h6 style="color: #424242">Settings Description</h6>
                            <v-card outlined class="pa-1" width="400">
                              {{ settingsName }}
                            </v-card>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="m-2" color="error" @click="close">Cancel</v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
        <mayventory-table
          :search="search"
          :headers="headers"
          :items="items"
          item-key="name"
          title="Settings"
          url="/app/settings/view/"
        >
        </mayventory-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  ADD_COMPANY_SETTINGS,
  GET_COMPANY_SETTINGS,
  UPDATE_COMPANY_SETTINGS,
} from "../../../constants/graphql";
import Swal from "sweetalert2";
import { handleError } from "../../../constants/error";
import store from "../../../store";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Product",
  },

  data() {
    return {
      search: "",
      selected: [],
      userPermission: this.$store.state.authData.userPermissions,
      headers: [
        {
          text: "S/N",
          value: "sno",
          width: "10%",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
        },
        { text: "Value", value: "value" },
        { text: "Action", value: "action" },
      ],
      items: [],
      dialog: false,
      loading: false,
      emptyRules: [(v) => !!v || "Field must not be empty"],
      valid: true,
      settingsName: "",
      settingsCode: "",
      settingsValue: "",
      settingsId: null,
      settingsType: "",
      settingValues: "",
    };
  },
  apollo: {
    items: {
      query: GET_COMPANY_SETTINGS,
      update(result) {
        let data = result.getCompanySettingsList;
        var valObj = data.filter(function (elem) {
          if (elem.type == "bool") return elem.value;
        });

        if (data.length > 0) {
          if (valObj[0] != undefined) {
            var val0 = valObj[0].value;
            if (val0 === "0") {
              valObj[0].value = false;
            } else if (val0 === "1") {
              valObj[0].value = true;
            }
          }
          if (valObj[1] != undefined) {
            var val1 = valObj[1].value;
            if (val1 === "0") {
              valObj[1].value = false;
            } else if (val1 === "1") {
              valObj[1].value = true;
            }
          }
        }
        return data;
      },
    },
  },
  computed: {
    addSettings() {
      if (this.userPermission.includes("add_company_settings")) {
        return true;
      }
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.$root.$refs.ST = this;
  },

  methods: {
    close() {
      this.dialog = false;
      this.isEditing = false;
    },
    refresh() {
      this.$apollo.queries.items.refetch();
    },
    edit(item) {
      this.settingsId = item.id;
      this.settingsName = item.name;
      this.settingsCode = item.code;
      this.settingsType = item.type;
      this.settingsValue = item.value;
      this.dialog = true;

      this.$apollo.queries.items.refetch();
    },
    updateSettings() {
      this.loading = true;
    },
    updateAlertLimit() {
      this.$apollo
        .mutate({
          mutation: UPDATE_COMPANY_SETTINGS,
          variables: {
            settingsId: parseInt(this.settingsId),
            value: this.settingsValue,
          },
        })
        .then((_) => {
          this.$apollo.queries.items.refetch();
          Swal.fire({
            toast: true,
            text: `${this.settingsName} has successfully updated`,
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
            position: "top-end",
          });
        });
      this.close();
    },
    updateText(value, code) {
      this.$apollo
        .mutate({
          mutation: UPDATE_COMPANY_SETTINGS,
          variables: {
            settingsId: value,
            value: this.settingsValue,
          },
        })
        .then((_) => {
          this.$apollo.queries.items.refetch();
          if (code == "currency_symbol") {
            store.dispatch("setCurrency", this.settingsValue);
          }
          Swal.fire({
            toast: true,
            text: `${this.settingsName} has successfully updated`,
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
            position: "top-end",
          });
        });
      this.close();
    },
    updateType(item, value) {
      if (value === null || value === false || value === "0") {
        value = false;
      }
      if (value === "1" || value === true) {
        value = true;
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_COMPANY_SETTINGS,
          variables: {
            settingsId: parseInt(this.settingsId),
            value: value,
          },
        })
        .then((_) => {
          this.$apollo.queries.items.refetch();
          Swal.fire({
            toast: true,
            text: `${this.settingsName} has successfully updated to ${value}`,
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
            position: "top-end",
          });
          let data = this.items;
          var valOObj = data.filter(function (elem) {
            if (elem.settings.type == "bool") return elem.value;
          });
          if (valOObj[0] != undefined) {
            var val0 = valOObj[0].value;
            if (val0 === "0") {
              valOObj[0].value = false;
            } else if (val0 === "1") {
              valOObj[0].value = true;
            }
          }
          if (valOObj[1] != undefined) {
            var val1 = valOObj[1].value;
            if (val1 === "0") {
              valOObj[1].value = false;
            } else if (val1 === "1") {
              valOObj[1].value = true;
            }
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 30px;
        padding-top: 30px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
      td {
        padding: 0.95rem !important;
      }
    }
  }

  .format-pre pre {
    background: #49483e;
    color: #f7f7f7;
    padding: 10px;
    font-size: 14px;
  }
}
</style>
